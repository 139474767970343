.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-group {
  margin-bottom: 1rem;
  display: flex;
}

.form-group label {
  width: 150px;
}

.form-section {
  border-bottom: 2px solid currentColor;
  font-weight: bold;
}

.LinkButtonContainer {
  display: inline-flex;
  flex-direction: row;
}

.LinkButton {
  width: 140px;
  height: 140px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: 1px;
  border-radius: 15px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin: 10px;
}

.LinkButton:hover {
  background-color: #fff;
  box-shadow: 0px 15px 20px #aaaaaa;
  color: #000;
  transform: translateY(-7px);
}

.LinkButtonPlusLeft {
  border-radius: 15px 0px 0px 15px;
  margin: 10px 0px 10px 10px;
}

.LinkButtonPlusLeft:hover {
  background-color: #fff;
  box-shadow: 0px 15px 20px #aaaaaa;
  color: #000;
  transform: translateY(-7px);
}

.LinkButtonPlusRight {
  border-radius: 0px 15px 15px 0px;
  margin: 10px 10px 10px 0px;
  width: 40px;
}

.LinkButton img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.LinkButton label {
  padding-top: 5px;
  height: 10px;
}

.LinkButtonPlusRight img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.LinkButtonPlusRight:hover {
  background-color: #fff;
  box-shadow: 0px 15px 20px #aaaaaa;
  color: #000;
  transform: translateY(-7px);
}

.LinkButtonAddOnly {
  border-radius: 15px 15px 15px 15px;
  height: 40px;
  width: auto;
}

.LinkButtonAddOnly label {
  margin: 10px;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

li {
  display: list-item;
}

blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}
